export default class {

	SetLayout(layout) {
		switch (layout) {
			case 'login':
				return 'is-max-login';
			case 'main':
				return 'is-fluid px-0';
			default:
				return 'is-max-desktop';
		}
	}

}