import api from '../config/api.json' with {type: 'json'};

export function resolvePath({ env, type, location, endpoint }) {

	var protocol;

	// console.log(env, type, location.host, endpoint);

	// Protocol

	const protocols = {
		'api': {
			'development': 'http',
			'staging': (location.host.includes('localhost')) ? 'http' : 'https',
			'production': 'https'
		},
		'ws': {
			'development': 'ws',
			'staging': (location.host.includes('localhost')) ? 'ws' : 'wss',
			'production': 'wss'
		}
	};
	
	if (['api', 'ws'].includes(type)) {
		protocol = protocols[type][env] || protocol;
	}

	// Port

	const portMap = {
		'api': 3090,
		'ws': 3000
	};
	
	const port = portMap[type];

	// Prefix

	const prefixMap = {
		'api': api.prefix,
		'ws': api.wsPrefix
	};

	const prefix = prefixMap[type];

	// Path
	
	const isDevelopment = env === 'development';
	const host = isDevelopment ? `localhost:${port}` : location.host;
	const path = `${protocol}://${host}/${prefix}/${endpoint}`;
	
	if (isDevelopment) {
		console.log(path);
	}

	return path;
}